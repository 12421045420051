.noWalletWrapper {
    padding: 24px 32px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    min-height: 615px;
    gap: 12px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
}

.walletIcon {
    width: 24px;
    height: 24px;
    display: block;
    padding: 0;
    background-color: #979797;
    mask-image: url("/src/assets/images/personalAccount/wallets.svg");
    mask-repeat: no-repeat;
    position: relative;
}

.walletText {
    color: #979797;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
    padding: 0;
    font-weight: 500;
    word-wrap: break-word;
}

.buttonWrapper {
    width: 200px;
}

.modalFormItem {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    gap: 8px;
    align-self: stretch;
}

.modalTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2a2e37;
    text-align: center;
    margin: 0 0 20px;
}

.modalDropdownSubtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}

.transactionModalDropdownSubtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    text-align: left;
    margin: 0;
    text-transform: uppercase;
}

.modalDropdown {
    display: flex;
    align-items: center;
    width: 100%;
}

.modalButtonsWrapper {
    display: flex;
    gap: 24px;
    margin-top: 60px;
    justify-content: center;
}

.error {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ff6471;
}

.cryptoDetail {
    display: flex;
    align-items: center;
}

.cryptoText {
    font-family: "Inter", sans-serif;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    line-height: 18px;
    font-weight: 500;
}

.cryptoText > div:nth-child(2) {
    text-transform: uppercase;
}

.walletsContainer {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    padding: 24px 32px;
    position: relative;
    overflow: hidden;
    min-height: 615px;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    white-space: nowrap;
}

.titleButtonWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 24px;
    width: auto;
}

.walletsTableWrapper {
    font-family: "Inter", sans-serif;
    color: #2a2e37;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.walletsTable tbody > tr > td {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.walletsTable thead > tr > th {
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 26px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 16px !important;
    text-transform: uppercase;
    border: none !important;
}

.walletsTable thead > tr > th::before {
    content: none !important;
}

.status_modal_title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2a2e37;
}

.status_modal_subtitle {
    margin-top: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
}

.status_flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.status_button_block {
    margin-top: 60px;
    width: 180px;
}

.noTransactionWrapper {
    padding: 24px 32px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    min-height: 615px;
    gap: 12px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
}

.transactionText {
    color: #979797;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
    padding: 0;
    font-weight: 500;
    word-wrap: break-word;
}

@media only screen and (max-width: 768px) {
    .titleWrapper {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .titleButtonWrapper {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        gap: 10px;
        margin-top: 16px;
    }

    .buttonWrapper {
        width: 100%;
        margin: 0 auto;
        > * {
            font-size: 14px;
        }
    }
}
