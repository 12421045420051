.noTransactionWrapper {
    padding: 24px 32px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    min-height: 615px;
    gap: 12px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
}

.transactionIcon {
    width: 24px;
    height: 24px;
    display: block;
    padding: 0;
    background-color: #979797;
    mask-image: url("/src/assets/images/personalAccount/transactions.svg");
    mask-repeat: no-repeat;
    position: relative;
}

.transactionText {
    color: #979797;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
    padding: 0;
    font-weight: 500;
    word-wrap: break-word;
}

.modalFormItem {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    gap: 8px;
    align-self: stretch;
}

.error {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ff6471;
    position: absolute;
}

.transactionsContainer {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    padding: 24px 32px;
    position: relative;
    overflow: hidden;
    min-height: 615px;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    white-space: nowrap;
}

.titleButtonWrapper {
    display: flex;
    justify-content: right;
    gap: 24px;
    width: 100%;
}

.transactionsTableWrapper {
    font-family: "Inter", sans-serif;
    color: #2a2e37;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.transactionsTable tbody > tr > td {
    margin-left: 0 !important;
    padding-left: 0 !important;
    line-height: 50px;
}

.transactionsTable thead > tr > th {
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 26px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 16px !important;
    text-transform: uppercase;
    border: none !important;
}

.transactionsTable thead > tr > th::before {
    content: none !important;
}

.buttonWrapper {
    width: 200px;
}

@media only screen and (max-width: 768px) {
    .titleWrapper {
        display: block;
        text-align: center;
    }

    .buttonWrapper {
        width: 100%;
    }

    .buttonWrapper > * {
        margin-top: 10px;
        font-size: 12px;
    }

    .titleButtonWrapper {
        gap: 10px;
    }
}
