.text_block {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    margin-left: 16px;
    transition: .3s all ease;
}

.active .text_block{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-color);
    opacity: 0.9;
}

.inactive:hover .img_block div{
    background: #2A2E37;
    transition: .3s all ease;
}

.inactive:hover .text_block {
    color: #2A2E37;
    transition: .3s all ease;
}

.img_block {
    width: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_accounts {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-image: url("../../assets/images/personalAccount/bank.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
}

.active .img_accounts {
    background-color: var(--primary-color);
}

.img_dashboard {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-image: url("../../assets/images/personalAccount/dashboard.svg");
    mask-repeat: no-repeat;
}

.active .img_dashboard {
    background-color: var(--primary-color);
}

.img_transfers {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-image: url("../../assets/images/personalAccount/transfers.svg");
}

.active .img_transfers {
    background-color: var(--primary-color);
}

.img_templates {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-image: url("../../assets/images/personalAccount/templates.svg");
}

.active .img_templates {
    background-color: var(--primary-color);
}

.img_cards {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-repeat: no-repeat;
    mask-image: url("../../assets/images/personalAccount/cards.svg");
}

.active .img_cards {
    background-color: var(--primary-color);
}

.img_crypto {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-repeat: no-repeat;
    mask-image: url("../../assets/images/personalAccount/crypto.svg");
}

.active .img_crypto {
    background-color: var(--primary-color);
}

.img_security {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-image: url("../../assets/images/personalAccount/security.svg");
}

.active .img_security {
    background-color: var(--primary-color);
}

.img_settings {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-image: url("../../assets/images/personalAccount/settings.svg");
}

.active .img_settings {
    background-color: var(--primary-color);
}

.img_currency {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-image: url("../../assets/images/personalAccount/currency.svg");
}

.active .img_currency {
    background-color: var(--primary-color);
}

.img_logout {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #979797;
    display: block;
    mask-image: url("../../assets/images/personalAccount/logout.svg");
}

@media only screen
and (max-device-width: 768px) {
    .text_block {
        color: #979797;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .active .text_block {
        width: auto;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}
