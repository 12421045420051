.wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999999;
}

.content_wrapper {
    min-width: 600px;
    height: fit-content;
    background: #ffffff;
    border-radius: 12px;
    position: absolute;
}

.tabHeader {
    display: flex;
    justify-content: space-around;
    position: sticky;
    top: 0;
    background: #fff;
}

.content {
    padding: 60px 125px;
    box-sizing: border-box;
}

.tabButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    flex: 1;
    padding: 8px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #979797;
}

.activeTab {
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
}

/* Responsive Design */
@media only screen and (max-device-width: 768px) {
    .content_wrapper {
        min-width: 0px !important;
        width: 100%;
    }

    .content {
        padding: 40px 20px;
    }
}
